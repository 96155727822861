module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-axe-core-react/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"debug":{"preview":true,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"url":"https://admin.millersapplehill.com/graphql","verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Miller's Apple Hill","short_name":"Apple Hill","start_url":"/","background_color":"#ffffff","theme_color":"#712B2B","display":"minimal-ui","icon":"src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ac7677cb99c5baa1d074056296874778"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
